import axios from "axios";
import { createRef, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Loader } from "@googlemaps/js-api-loader";
import { FcCallback } from "react-icons/fc";
import decoder from "decode-google-map-polyline";
import "./App.css";
import "./css/trackview.css";
import urls from "./utils/url.utils";
import currentLocationIcon from "./assets/images/current_location.png";
import greenDotIcon from "./assets/images/green-dot.png";
import redDotIcon from "./assets/images/red-dot.png";
import LanguageJSON from "./utils/language.json";

const colors = [
  "#5D8233",
  "#3DB2FF",
  "#5E454B",
  "#7D1935",
  "#865439",
  "#297F87",
  "#DF2E2E",
];

function TrackView() {
  const [cachecleared, setCacheCleared] = useState(false);
  const [mapRef, setMapRef] = useState(false);
  const [config, setConfig] = useState(null);
  const [language, setLanguage] = useState(LanguageJSON);
  const [error, setError] = useState("");
  const [rideDetails, setRideDetailes] = useState(false);
  const [cantTrack, setCantTrack] = useState("");
  const [currentLocationMarker, setCurrentLocationMarker] = useState(false);
  const [mode, setMode] = useState("");
  // for map
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState(false);

  const working = process.env.REACT_APP_CLIENT_KEY || "zervx-development"; // localhost ,zervx-development,starmov
  switch (working) {
    case "starmov": // Portuguese
      language.BOOKING = "Reserva";
      language.ENDED = "Terminou";
      language.EXPIRED = "Expirada";
      language.USERCANCELLED = "Usuário cancelado";
      language.PROFESSIONALCANCELLED = "Profissional cancelado";
      language.SOMETHING_WENT_WRONG = "Algo deu errado";
      language.INVALID_LINK = "Link inválido";
      language.LOADING = "Carregando...";
      language.PICKUP = "Inicio";
      language.DROP = "Destino";
      break;

    default:
      break;
  }

  //
  const fetchConfig = async () => {
    try {
      const { data } = await axios.get(urls.HOST + urls.REACT_CONFIG);
      setConfig(data.data);
    } catch (error) {
      setError(language.SOMETHING_WENT_WRONG);
    }
  };
  const fetchRideDetailes = async () => {
    try {
      const { data } = await axios.get(
        urls.HOST + urls.TRACK + "/" + window.location.pathname.substring(1)
      );
      if (
        data.bookingStatus === "EXPIRED" ||
        data.bookingStatus === "ENDED" ||
        data.bookingStatus === "USERCANCELLED" ||
        data.bookingStatus === "PROFESSIONALCANCELLED"
      ) {
        setCantTrack(language.BOOKING + " " + language[data.bookingStatus]);
      }
      setRideDetailes(data);
    } catch (error) {
      if (error?.response?.status === 404) {
        setError(language.INVALID_LINK);
      } else {
        setError(language.SOMETHING_WENT_WRONG);
      }
    }
  };

  useEffect(() => {
    if (currentLocationMarker) {
      setInterval(() => {
        const fetchCurrentLocation = async () => {
          try {
            const { data } = await axios.get(
              urls.HOST +
                urls.TRACK_CURRENT_LOCATION +
                "/" +
                window.location.pathname.substring(1)
            );
            currentLocationMarker.setPosition(
              new window.google.maps.LatLng(
                data.data.location.lat,
                data.data.location.lng
              )
            );
            map.panTo(
              new window.google.maps.LatLng(
                data.data.location.lat,
                data.data.location.lng
              )
            );
          } catch (error) {
            console.log(error);
          }
        };
        fetchCurrentLocation();
      }, 10000);
    }
  }, [currentLocationMarker]);

  useEffect(() => {
    if (
      map &&
      rideDetails?.encodedPolyline &&
      rideDetails?.encodedPolyline[0]
    ) {
      rideDetails?.encodedPolyline.forEach((each, idx) => {
        const polylines = new window.google.maps.Polyline({
          path: decoder(each),
          geodesic: true,
          strokeColor: colors[idx % 5],
          strokeOpacity: 1,
          strokeWeight: 0,
        });
        if (polylines) {
          polylines.setMap(map);
          const bounds = new window.google.maps.LatLngBounds();
          decoder(each).forEach((e, i) =>
            bounds.extend({ lat: e.lat, lng: e.lng })
          );
          map.fitBounds(bounds);
        }
      });
    }
    if (map) {
      map.setCenter({ lat: rideDetails.pickupLat, lng: rideDetails.pickupLng });

      // make origin and destination marker

      // origin
      const originMarker = new window.google.maps.Marker({
        map,
        icon: {
          url: greenDotIcon,
          scaledSize: new window.google.maps.Size(50, 50),
        },
        position: { lat: rideDetails.pickupLat, lng: rideDetails.pickupLng },
      });
      const originInfoWindow = new window.google.maps.InfoWindow({
        content:
          `
            <div>
            <h1 style="color:black;font-size:15px;">` +
          language.PICKUP +
          `</h1>
            </div>
            `,
      });
      originInfoWindow.open(map, originMarker);
      //destination
      const destinationMarker = new window.google.maps.Marker({
        map,
        icon: {
          url: redDotIcon,
          scaledSize: new window.google.maps.Size(50, 50),
        },
        position: {
          lat: rideDetails.destinationLat,
          lng: rideDetails.destinationLng,
        },
      });
      const destinationInfoWindow = new window.google.maps.InfoWindow({
        content:
          `
            <div>
            <h1 style="color:black;font-size:15px;">` +
          language.DROP +
          `</h1>
            </div>
            `,
      });
      destinationInfoWindow.open(map, destinationMarker);

      const professionalLocationMarker = new window.google.maps.Marker({
        map,
        icon: {
          url: currentLocationIcon,
          scaledSize: new window.google.maps.Size(30, 30),
        },

        position: {
          lat: rideDetails.professional.location.lat,
          lng: rideDetails.professional.location.lng,
        },
      });
      setCurrentLocationMarker(professionalLocationMarker);
    }
  }, [map]);
  useEffect(() => {
    if (mapLoaded && mapRef) {
      console.log("i am inside");
      const googleMap = new window.google.maps.Map(mapRef, {
        center: { lat: 12.9791551, lng: 80.2007085 },
        zoom: 10,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      });
      googleMap.setZoom(15);
      setMap(googleMap);
    }
  }, [mapLoaded, mapRef]);

  useEffect(() => {
    if (config) {
      const loader = new Loader({
        apiKey: config.mapApi.web,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          setMapLoaded(true);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [config]);

  useEffect(() => {
    const modesList = {
      starmov: "Starmov",
      zervx: "Zervx",
      pamworld: "Pamworld",
    };
    setMode(modesList[urls.MODE]);
    //
    (async function () {
      await fetchConfig();
      await fetchRideDetailes();
      // map intialization
    })();
    //
  }, []);

  return (
    <>
      <Helmet>
        <title>{mode + " Track"} </title>
        <meta
          name="description"
          content={mode + " track your friends ride location"}
        />
        <link rel="icon" href={config?.favicon} />
      </Helmet>
      {!!cantTrack === true && <h1 style={{ color: "green" }}>{cantTrack}</h1>}
      {!!error === true && <h1 style={{ color: "red" }}>{error}</h1>}
      {!!error === false && !!cantTrack === false && rideDetails && (
        <div className="track-view">
          <header className="track-header">
            <h1>{rideDetails.bookingId}</h1>
            <div className="boarding">
              <div className="address">
                <div>{language.PICKUP}</div>
                <div>{rideDetails.pickup}</div>
              </div>
              <div className="address">
                <div>{language.DROP}</div>
                <div>{rideDetails.drop}</div>
              </div>
            </div>
          </header>
          <section>
            <div className="map-wrap">
              <div className="estimation-time">
                {/* Ride end in {rideDetails.estimationTime} */}
                {language.LOADING}
              </div>

              <div
                className="map-view"
                ref={(element) => {
                  if (element) {
                    setMapRef((previousElement) =>
                      previousElement === element ? previousElement : element
                    );
                  }
                }}
              ></div>
            </div>
          </section>
          <section className="conclude">
            <div className="conclude-wrap">
              <div className="conclude-parts">
                <div className="img">
                  {" "}
                  <img
                    src={
                      rideDetails.vehicle.categoryImage ||
                      "https://cdn2.iconfinder.com/data/icons/rounded-set-1/512/Car-1024.png"
                    }
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="conclude-content">
                  <div>{rideDetails.vehicle.model}</div>
                  <div
                    style={{
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.3)",
                    }}
                  >
                    {rideDetails.vehicle.color}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="conclude-data"
                >
                  <div style={{ textTransform: "uppercase" }}>
                    {rideDetails.vehicle.plateNumber}
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="conclude-parts">
                <div className="img">
                  <img
                    src={rideDetails.professional.avatar}
                    alt=""
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                </div>

                <div className="conclude-content">
                  <div>{rideDetails.professional.name}</div>
                  <div>
                    {parseFloat(rideDetails.professional.rating).toFixed(1)} 🌟
                  </div>
                </div>
                <div className="conclude-data">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href={`tel:${rideDetails.professional.phone.code}${rideDetails.professional.phone.number}`}
                    >
                      <FcCallback style={{ width: "40px", height: "40px" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <div className="App">
      <TrackView />
    </div>
  );
}

export default App;
